import LoginPage from "../views/onboarding/login-page.vue";
import ForgotPassword from "../views/onboarding/forgot-password.vue";
const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "forgot",
    path: "/forgot-password",
    component: ForgotPassword,
  },
];

export default routes;
