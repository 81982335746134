<template>
    <div id="recent-orders">
        <h2 id="recent-orders">Recent Orders</h2>
        <!-- <div id="order-buttons">
           <left-button></left-button>
           <right-button></right-button>
        </div> -->
        <div id="recent-table">
            <div id="table-heading">
                <div class="heading">Order ID</div>
                <div class="heading">Status</div>
                <div class="heading">Food Outlet</div>
                <div class="heading">Driver</div>
                <div class="heading">Delivery Time</div>
                <div></div>
            </div>
            <div class="table-divider"></div>
            <div v-if="Show">
                <div id="table-content" v-for="value,index in TableValues" :key="index">
                    <div class="table-row">
                        <div class="entry">{{ value.id}}</div>
                        <div class="entry">{{ value.status }}</div>
                        <div class="entry" v-if="value.outletName">{{ value.outletName.slice(0,10) }}....</div>
                        <div class="entry" v-else>Pending...</div>
                        <div class="entry" v-if="value.riderName">{{ value.riderName }}</div>
                        <div class="entry" v-else>Pending...</div>
                        <div class="entry">{{ value.deliveryTime }}</div>
                        <div class="table-btn" @click="redirect(value.id)">
                            Payment Details
                        </div>
                    </div>
                    <div class="table-divider"></div>
                </div>
            </div>
            <h2 v-else id="no-recent">No recent orders placed</h2>
        </div>
    </div>
</template>

<script>
// import LeftButton from '../general/left-button.vue'
// import RightButton from '../general/right-button.vue'
export default {
   // components:{LeftButton,RightButton},
    props:['value'],
    computed:{
        TableValues(){
            return this.value
        },
        Show(){
            try{
                let val = Object.keys(this.value).length
                if(val>0) return true
                else return false
            }
            catch(err){
                return false
            }
            
        }
    
    },
    methods:{
        redirect(value){
        this.$router.push({name:'driverPayment',params:{id:value}})
      }
    },
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#recent-orders{
    color: #413F3F;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.247px;
margin-left:1.5vw;
margin-top:2vh;
}
#order-buttons{
    float: right;
    margin-right:2vw;
    display: flex;
}
#recent-table{
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
border: 1.094px solid var(--Brand-color-3, #8D8D8D);
border-bottom: none;
background: #FFF;
box-shadow: 0px 1.09416px 2.18831px 0px rgba(0, 0, 0, 0.10);
width:51vw;
margin-left:0vw;
height: fit-content;
min-height:60vh;
max-height:63vh;
margin-top:3vh;
overflow:auto;
padding-top:1.5vh;
padding-bottom:2vh;
padding-left:1.75vw;
padding-right:1.75vw;
}

/* WebKit */
/* Width */
#recent-table::-webkit-scrollbar {
    width:0;
    height:8px; /* Width of the scrollbar */
  }
  
  /* Track */
  #recent-table::-webkit-scrollbar-track {
    background: #8f918f; /* Green background color */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  /* Handle */
  #recent-table::-webkit-scrollbar-thumb {
    background: rgb(164, 164, 164); /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners for the handle */
  }
  
  /* Handle on hover */
  #recent-table::-webkit-scrollbar-thumb:hover {
    background:rgb(49, 148, 49); /* Color of the scrollbar handle on hover */
  }
#table-heading{
    display: grid;
    grid-template-columns: 10vw 10vw 10vw 10vw 6vw 7vw;
    margin-bottom:2vh;
}
.heading{
    color: #413F3F;
font-family: Inter;
font-size: 15.318px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.219px;
text-align: center;
}
.table-divider{
    background:#8d8d8d5c;
    height:1px;
    width:53vw;
    margin-left:0.25vw;
    margin-top:1vh;
    margin-bottom:1vh;
}
.table-row{
    display:grid;
    grid-template-columns: 10vw 10vw 10vw 10vw 6vw 7vw; 
}
.entry{
    color: #413F3F;
font-family: Inter;
font-size: 15.318px;
font-style: normal;
font-weight: 500;
line-height: 41px;
letter-spacing: 0.219px;
word-wrap: break-word;
text-align: center;
}
.table-btn{
    border-radius: 10px;
background: #25AD34;
width:8vw;
height:41px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14.506px;
font-style: normal;
font-weight: 400;
line-height: 41px;
cursor: pointer;
}
#no-recent{
    text-align: center;
    font-family:Inter;
    font-size:24px;
    font-weight:600;
    color:#413F3F;
    margin-top:5vh;
}
</style>