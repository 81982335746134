<template>
    <div id="container">
        <nav-bar></nav-bar>
        <div id="loader" v-if="Loading">
          <loader-comp></loader-comp>
        </div>
        <div id="content" v-else>
          <div id="close-button" @click="close">X</div>
            <GMapMap :center="Center"
            :zoom="10" :options="options" class="map">
            <GMapMarker
            v-for="(place, index) in Drivers"
            :key="index"
            :position="place.coordinates"
            :clickable="false"
          :draggable="false"
          :icon="{
            url:require('../../assets/icons/driver-icon.png'),
            scaledSize: {width: 35, height: 65},
        }"
          ></GMapMarker>
          <GMapMarker
          v-for="(place, index) in Outlets"
          :key="index"
          :position="place.coordinates"
          :clickable="false"
        :draggable="false"
        :icon="{
          url:require('../../assets/icons/customer-icon.png'),
          scaledSize: {width: 50, height: 50},
      }"
        ></GMapMarker>
        </GMapMap>
        </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
    components:{NavBar,LoaderComp},
    data(){
        return{
      options:{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            /*
             styles: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [{ color: '#d7d7d7' }], // Set the background color here (blue in this case)
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#FFFFFF',weight:3,gama:0.5,saturation: -50 }] // Set the road color here (blue in this case)
          },
          {
            featureType: 'label',
            elementType: 'labels.text.fill',
            stylers: [
              { visibility: 'off' }    // Hide text labels
            ]
          },
          
        ],
            */
      },
      outlets:[],
        }
    },
    computed:{
        Drivers(){
          return this.$store.getters['dashboard/getDriverCoordinates']
        },
        Outlets(){
          return this.outlets;
        },
        Center(){
          //return {lat:53.799999,lng:-1.750000}
           return this.$store.getters['user/getCoordinates']
        },
        Loading(){
          return this.$store.getters['user/getLoader']
        }
    },
    methods:{
      close(){
        this.$router.go(-1)
      }
    },
   async mounted(){
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('dashboard/homeData')
    this.$store.commit('user/setLoader',{value:false})
    this.loading=false;
    if(res.status==200){
      let coordinates = res.data.outletCoordinatesList
      for(let i=0;i<coordinates.length;i++){
        this.outlets.push({
          coordinates:{
            lat:parseFloat(parseFloat(coordinates[i].business_coordinatesX).toFixed(7)),
            lng:parseFloat(parseFloat(coordinates[i].business_coordinatesY).toFixed(7))
          }
        })
      }
    }
   }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
}
#content::-webkit-scrollbar{
  display: none;
}
#loader{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display:flex;
  align-items: center;
  justify-content: center;
}
.map{
    width:84vw;
    margin-left:.5vw;
    height:95vh;
    margin-top:2.5vh;
}
#close-button{
  width:30px;
  height:30px;
  border-radius: 30px;
  background: #25AD34;
  display:flex;
  justify-content:center;
  align-items:center;
  color:white;
  font-family:Inter;
  font-size:16px;
  font-weight:400;
  float:right;
  z-index:1;
  margin-top:1vh;
  margin-bottom:1vh;
  cursor:pointer
}
</style>