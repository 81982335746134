<template>
    <div id="search">
        <div id="search-header">
            <h2>Outlets</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
                <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
              </svg>
        </div>
        <div id="search-bar">
            <div id="search-input">
                <input type="text" :placeholder="Placeholder" v-model="value" id="input-bar" @keydown="moveToNextInput($event)">
                <div id="magnify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" @click="search">
                        <circle cx="9.24445" cy="9.37595" r="7.63226" stroke="#25AD34" stroke-width="2.57595"/>
                        <path d="M13.9824 15.0913L20.184 21.2935" stroke="#25AD34" stroke-width="2.57595"/>
                      </svg>
                </div>
            </div>
          <!-- <filter-button></filter-button>
          <add-button></add-button> -->
        </div>
    </div>
</template>

<script>
// import FilterButton from '../../components/general/filter-button'
// import AddButton from '../../components/general/add-button.vue'
export default {
    data(){
        return{
            value:'',
            placeholder:'Search Outlet Name'
        }
    },
    computed:{
        Placeholder(){
            return this.placeholder;
        }
    },
    methods:{
        async search(){
            if(this.value!=''){
              let res = await this.$store.dispatch('outlet/searchOutlets',{value:this.value})
              if(res=='' || !res)
              {
                this.placeholder = "No match found"
                this.value=''
              }
              else
              {
                let markers = this.$store.getters['dashboard/getOutletCoordinates']
                markers.push({coordinates:{lat:res.lat,lng:res.lng}})
                this.$store.commit('outlet/setCoordinates',{latitude:res.lat,longitude:res.lng})
                this.placeholder = "Search Outlet Name"
                this.value = ''
              }
            }
        },
        async moveToNextInput(event) {
      if (event.key === 'Enter') {
          await this.search()
      }
              }
    }
    //components:{FilterButton,AddButton}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Libre+Bodoni&family=Montserrat:wght@400;500&display=swap');

#search-header{
    display: flex;
    padding-left:3vw;
    align-items: center;
}
#search-header h2{
    color: #333;
font-family: Inter;
font-size: 25.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
    margin-left:4px;
}
#search-bar{
    margin-top:2vh;
    margin-left:3vw;
    display: flex;
}
#search-input{
    border-radius: 51.519px;
border: 1.288px solid var(--brand-color, #25AD34);
background: #FFF;
box-shadow: 0px 1.28797px 2.57595px 0px rgba(0, 0, 0, 0.15);
height: 52px;
width:25vw;
padding-left:0px;
padding-right:2vw;
padding-top:0px;
margin-top:0px;
display: flex;
}
#input-bar,
#input-bar::selection,
#input-bar:active{
    background: transparent;
    outline:none;
    border:none;
    width:20vw;
    height:52px;
    margin:0px;
    padding-left:1vw;
    color: rgba(0, 0, 0, 0.752);
    font-family: Montserrat;
    font-size: 18.032px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px; /* 171.429% */
    letter-spacing: 0.258px;
}
#input-bar::placeholder{
    color: #25AD34;
font-family: Montserrat;
font-size: 18.032px;
font-style: normal;
font-weight: 500;
line-height: 52px; /* 171.429% */
letter-spacing: 0.258px;
}
#magnify{
    margin-left:2.5vw;
    padding-top:15px;
}

</style>