<template>
    <div id="back-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none" id="back-svg">
            <g clip-path="url(#clip0_1391_270)">
            <path d="M9.0791 2.20731L3.72196 7.56445L9.0791 12.9216L8.00767 15.0645L0.507672 7.56445L8.00767 0.0644495L9.0791 2.20731Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_1391_270">
            <rect width="8.57143" height="15" fill="white" transform="matrix(-1 0 0 -1 9.0791 15.0645)"/>
            </clipPath>
            </defs>
            </svg>
    </div>
</template>

<style scoped>
#back-button{
    display: inline-flex;
height: 49px;
width:49px;
align-items: center;
justify-content: center;
flex-shrink: 0;
border-radius: 49px;
background: #25AD34;
}
#back-svg{
    width: 8.571px;
height: 15px;
}
#back-button{
    cursor: pointer;
}
</style>