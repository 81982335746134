<template>
    <div id="outlet-comp">
     <div id="outlet-col1">
        <img :src="Image" :alt="name" @error='handleImageError'>
        <h3>{{ name }}</h3>
     </div>
     <div id="outlet-col2" @click="redirect">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
            <path d="M11.4592 11.6786C12.0076 11.1302 12.0076 10.2412 11.4592 9.69286L2.52353 0.757144C1.97519 0.208804 1.08616 0.208804 0.537817 0.757144C-0.0105228 1.30548 -0.0105228 2.19452 0.537817 2.74286L8.48067 10.6857L0.537817 18.6286C-0.0105228 19.1769 -0.0105228 20.0659 0.537817 20.6143C1.08616 21.1626 1.97519 21.1626 2.52353 20.6143L11.4592 11.6786ZM9.14258 12.0898H10.4664V9.2816H9.14258V12.0898Z" fill="#25AD34"/>
          </svg>
     </div>
    </div>
</template>

<script>
export default {
 props:['name','picture','id'],
 data(){
    return{
        image:this.picture
    }
},
computed:{
    Image(){
        return this.image;
    }
},
 methods:{
    redirect(){
     this.$router.push({name:'outlet',params:{id:this.id}})
    },
    handleImageError() {
      // If the image fails to load, set the source to the default image
      this.image = "hhttps://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae"
    }
 }   
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#outlet-comp{
    border-radius: 5.295px;
background: var(--White, #FFF);
box-shadow: 0px 1.32381px 2.64762px 0px rgba(0, 0, 0, 0.10);
width:22vw;
height:71px;
margin-left:1.5vw;
margin-right:1.5vw;
display: grid;
grid-template-columns: 19vw 3vw;
}
#outlet-col1{
    display: flex;
    width:19vw;
    height: 71px;
    overflow: hidden;
    align-items: center;
    padding-left:0.5vw;
}
#outlet-col1 img{
    width:55px;
    height: 55px;
    border-radius: 55px;
}
#outlet-col1 h3{
    color: #413F3F;
    font-family: Inter;
    font-size: 16.533px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.265px;
    margin-left:24px;
}
#outlet-col2{
    width:3vw;
    height: 71px;
    border-radius: 2.648px;
background: #FFF;
box-shadow: -1.32381px 1.32381px 2.64762px 0px rgba(0, 0, 0, 0.10);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}
</style>