import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import VueGoogleMaps from "@fawmi/vue-google-maps";
createApp(App)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAPS_KEY,
      libraries: "places",
    },
  })
  .use(store)
  .use(router)
  .mount("#app");
