export default {
  getActiveOutlet(state) {
    return state.activeOutlet;
  },
  getTotalOutlet(state) {
    return state.totalOutlet;
  },
  getActiveRiders(state) {
    return state.activeRiders;
  },
  getTotalRiders(state) {
    return state.totalRiders;
  },
  getDriverCoordinates(state) {
    return state.driverCoordinates;
  },
  getOutletCoordinates(state) {
    return state.outletCoordinates;
  },
  getOrders(state) {
    return state.orders;
  },
};
