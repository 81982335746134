import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      activeOutlet: 0,
      totalOutlet: 0,
      activeRiders: 0,
      totalRiders: 0,
      driverCoordinates: [],
      outletCoordinates: [],
      orders: {}, // this will map order id to driver id
    };
  },
  mutations,
  getters,
  actions,
};
