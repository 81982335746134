<template>
    <div id="dropdown">
        <div id="selected">
          <h2>{{ Selected }}</h2>
          <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
   @click="toggleFn">
  <path d="M6 9l6 6 6-6" />
</svg>
        </div>
        <div :class="Toggle==true?'drop-expand':'drop-collapse'">
            <div class="option" v-for="year,index in Year" :key="index" @click="toggleYear(year)">
                {{ year }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            year:[],
            toggle:false,
            selected:''
        }
    },
    computed:{
        Year(){
            return this.year;
        },
        Toggle(){
            return this.toggle;
        },
        Selected(){
            return this.selected;
        }
    },
    methods:{
        toggleFn(){
            this.toggle=!this.toggle;
        },
        toggleYear(value){
            this.selected=value;
            this.toggle=false;
            this.$emit('toggleYear',value);
        }
    },
    mounted(){
        const currentYear = new Date().getFullYear();
  const endYear = 2023;
  
  const yearsArray = [];
  
  for (let year = currentYear; year >= endYear; year--) {
    yearsArray.push(year);
  }
  this.year=yearsArray;
  this.selected=currentYear
    }
}
</script>

<style scoped>
#dropdown{
    float:right;
    margin-right:4vw;
    width:8vw;
    height:35px;
    border: 1px solid #25AD34;
    border-radius:10px;
    z-index: 2;
    background-color:white;
    margin-bottom:40px;
    margin-top:2vh;

}
#selected{
    width:8vw;
    height:35px;
    display:flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
}
#selected h2{
    font-size:18px;
    font-weight:500;
    font-family: Inter;
    margin-right:15px;
}
.drop-collapse{
    display:none;
}
.drop-expand{
    width:8vw;
    height:fit-content;
    max-height:80px;
    overflow-x:hidden ;
    overflow-y:auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background:white;
    z-index:1;
    border-bottom:1px solid #25AD34;
    border-right:1px solid #25AD34; 
    border-left:1px solid #25AD34;
}
.drop-expand::-webkit-scrollbar{
    display:none;
}
.option{
    text-align:center;
    font-family:Inter;
    font-size:16px;
    font-weight:400;
    cursor:pointer;
}
.option:hover{
    width:8vw;
    font-size:16px;
    color:white;
    font-family:Inter;
    text-align:center;
    background: #25AD34;
    cursor:pointer;
}
</style>