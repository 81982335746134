const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";

export default {
  async getOutlets() {
    try {
      const reqUrl = `${hostName}/admin/outlets`;
      const reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.outlets,
          activeFoodOutetsCount: res.data.activeFoodOutetsCount,
          totalFoodOutetsCount: res.data.totalFoodOutetsCount,
          activeDriversCount: res.data.activeDriversCount,
          totalDriversCount: res.data.totalDriversCount,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async getOutlet(context, payload) {
    try {
      const reqUrl = `${hostName}/admin/outlet-detail/${payload.value}`;
      const reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.outlet,
          orders: res.data.ordersList,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async suspendOutlet(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/suspend-outlet-toggle`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let reqBody = {
        outletID: payload.value,
      };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return { status: res.status };
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async deleteOutlet(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/outlet`;
      let reqBody = {
        outletID: payload.id,
        password: payload.password,
      };
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.delete(reqUrl, {
        headers: reqHeaders,
        data: reqBody,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async searchOutlets(context, payload) {
    try {
      let outlets = context.getters["getOutlets"];
      let val = outlets[payload.value];
      return val;
    } catch (err) {
      return "";
    }
  },
  async weekData(context, payload) {
    try {
      const reqUrl = `${hostName}/admin/payments-outlet`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      const reqBody = { date: payload.date, outletId: payload.driver };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      if (res.status == 200)
        return { status: 200, data: res.data.outletPayments };
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
