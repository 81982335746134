export default {
  changeForgotPassword(state, payload) {
    state.forgotPage = payload.value;
  },
  changeMenu(state, payload) {
    state.menu = payload.value;
  },
  setLoader(state, payload) {
    state.loading = payload.value;
  },
  setCoordinates(state, payload) {
    state.center = {
      lat: parseFloat(payload.latitude),
      lng: parseFloat(payload.longitude),
    };
  },
  setInitial(state) {
    state.center = {
      lat: 53.799999,
      lng: -1.751711,
    };
  },
};
