export default {
  setSuspended(state, payload) {
    state.suspended = payload.value;
  },
  setComp(state, payload) {
    state.comp = payload.value;
  },
  setRejected(state, payload) {
    state.rejected = payload.value;
  },
  setDrivers(state, payload) {
    state.drivers = payload.value;
  },
  setCoordinates(state, payload) {
    state.coordinates = payload.value;
  },
  setCenter(state, payload) {
    state.center = {
      lat: parseFloat(payload.latitude),
      lng: parseFloat(payload.longitude),
    };
  },
  setInitial(state) {
    state.center = {
      lat: 53.799999,
      lng: -1.751711,
    };
  },
};
