export default {
  setActiveOutlet(state, payload) {
    state.activeOutlet = payload.value;
  },
  setTotalOutlet(state, payload) {
    state.totalOutlet = payload.value;
  },
  setActiveRiders(state, payload) {
    state.activeRiders = payload.value;
  },
  setTotalRiders(state, payload) {
    state.totalRiders = payload.value;
  },
  setDriverCoordinates(state, payload) {
    state.driverCoordinates = payload.value;
  },
  setOutletCoordinates(state, payload) {
    let coordinates = [];
    for (let i = 0; i < payload.value.length; i++) {
      coordinates.push({
        coordinates: {
          lat: parseFloat(
            parseFloat(payload.value[i].business_coordinatesX).toFixed(7)
          ),
          lng: parseFloat(
            parseFloat(payload.value[i].business_coordinatesY).toFixed(7)
          ),
        },
      });
    }
    state.outletCoordinates = coordinates;
  },
  setOrders(state, payload) {
    state.orders = payload.value;
  },
};
