import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      forgotPage: 0, // forgot password page
      menu: 0, // this for the side menu bar
      loading: false,
      center: {},
    };
  },
  mutations,
  getters,
  actions,
};
