export default {
  getForgotPage(state) {
    return state.forgotPage;
  },
  getMenu(state) {
    return state.menu;
  },
  getLoader(state) {
    return state.loading;
  },
  getCoordinates(state) {
    return state.center;
  },
};
