<template>
    <div id="container">
        <div id="form">
            <back-button id="back-button" @click="back"></back-button>
            <div id="sign-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="5" viewBox="0 0 74 5" fill="none">
                    <path d="M73.0111 2.64648L70.7017 0.337083L68.3923 2.64648L70.7017 4.95589L73.0111 2.64648ZM70.7017 2.24648H0.527344V3.04648H70.7017V2.24648Z" fill="#25AD34"/>
                  </svg>
                  <h2>Forgot Password</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="5" viewBox="0 0 73 5" fill="none">
                    <path d="M0.333177 2.64648L2.64258 4.95589L4.95198 2.64648L2.64258 0.337083L0.333177 2.64648ZM72.8169 2.24648H2.64258V3.04648H72.8169V2.24648Z" fill="#25AD34"/>
                  </svg>
            </div>
            <div id="form-box">
                <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Enter Email" v-model="value">
                <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
            </div>
            <loader-comp v-if="Loading" style="margin-top:10px;margin-bottom:10px;"></loader-comp>
            <submit-button Text="Send OTP" @click="submit"></submit-button>
        </div>
    </div>
</template>

<script>
import BackButton from '../../general/back-button.vue'
import SubmitButton from '../../general/submit-button.vue'
import LoaderComp from '../../general/loader-comp.vue'
import Cookies from 'js-cookie'
export default {
    components:{BackButton,SubmitButton,LoaderComp},
    data(){
        return{
            value:'',
            errorField:'',
                errorMessage:""
        }
    },
    computed:{
            Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            },
            Loading(){
                return this.$store.getters['user/getLoader']
            }
        },
    methods:{
        back(){
            this.$router.push({name:'login'})
        },
        async submit(){
            let emailCheck = await this.email(this.value)
            if(this.value == ''){
                     this.errorField = 1;
                     this.errorMessage = "This field is required"
                }
            
            else if(!emailCheck){
                this.errorField = 1;
                     this.errorMessage = "Please Enter a valid email"
                     return
            }
          else{
            this.$store.commit('user/setLoader',{value:true})
            let status = await this.$store.dispatch('user/forgotPassword1',{email:this.value})
            this.$store.commit('user/setLoader',{value:false})
            if(status==200){
            Cookies.set('password-email',this.value,{expires:1})
            this.$store.commit('user/changeForgotPassword',{value:1})
        }
        else if(status==404){
            this.errorField=1;
            this.errorMessage="This email does not exists"
        }
        else{
            this.errorField=1;
            this.errorMessage="Please enter valid credentials"
        }
          }
        },
        async email(value){ // email address
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                return emailPattern.test(value);
            },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}
#sign-title{
    align-items: center;
    width:100vw;
    display: inline-flex;
    align-items: center; /* Vertical alignment */
justify-content: center; /* Horizontal alignment */
}

    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      #back-button{
        margin-left:30px;
        margin-top:20px;
        cursor: pointer;
    }
    #sign-title h2{
        color: #413F3F;
text-align: center;
font-family: Inter;
font-size: 24.99px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
    }
    #sign-title{
        margin-top:5px;
    }
    #form-box{
        margin-top:15vh;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:18px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:18.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:10px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
cursor: pointer;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:35.75vw;
        margin-top:-16px;
    }
</style>