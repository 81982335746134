<template>
    <div id="search">
        <div id="search-header">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" id="back" @click="back">
                <g clip-path="url(#clip0_847_20518)">
                  <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
                </g>
                <defs>
                  <clipPath id="clip0_847_20518">
                    <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                  </clipPath>
                </defs>
              </svg>
            <h2>Delivery History</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
                <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
              </svg>
        </div>
        <div id="search-bar">
            <div id="search-input">
                <input type="text" :placeholder="Placeholder" v-model="value" id="input-bar">
                <div id="magnify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" @click="Search">
                        <circle cx="9.24445" cy="9.37595" r="7.63226" stroke="#25AD34" stroke-width="2.57595"/>
                        <path d="M13.9824 15.0913L20.184 21.2935" stroke="#25AD34" stroke-width="2.57595"/>
                      </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['id'],
    data(){
        return{
            value:'',
            placeholder:'Search Order ID'
        }
    },
    computed:{
        Placeholder(){
            return this.placeholder;
        }
    },
    methods:{
        back(){
            this.$router.go(-1)
        },
        async Search(){
            if(this.value==''){
                this.placeholder='Please enter a value'
                return
            }
           this.$store.commit('user/setLoader',{value:true})
           let res = await this.$store.dispatch('payment/paymentDetails',{value:this.value})
           this.$store.commit('user/setLoader',{value:false})
           if(res.status==200){
               this.$router.push({name:'detailedPayment',params:{id:this.value}})    
           }
           if(res.status==400){
                  this.placeholder='Order Not Found'
                  this.value=''
           }
           else{
            this.placeholder = 'Order not found'
            this.value=''
           }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Libre+Bodoni&family=Montserrat:wght@400;500&display=swap');

#search-header{
    display: flex;
    padding-left:1vw;
    align-items: center;
}
#back{
    margin-right:2vw;
    cursor: pointer;
}
#search-header h2{
    color: #333;
font-family: Inter;
font-size: 24.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
    margin-left:4px;
}
#search-bar{
    margin-top:2vh;
    margin-left:3vw;
    display: flex;
}
#search-input{
    border-radius: 51.519px;
border: 1.288px solid var(--brand-color, #25AD34);
background: #FFF;
box-shadow: 0px 1.28797px 2.57595px 0px rgba(0, 0, 0, 0.15);
height: 52px;
width:30vw;
padding-left:0px;
padding-right:2vw;
padding-top:0px;
margin-top:0px;
display: flex;
}
#input-bar,
#input-bar::selection,
#input-bar:active{
    background: transparent;
    outline:none;
    border:none;
    width:25vw;
    height:52px;
    margin:0px;
    padding-left:1vw;
    color: rgba(0, 0, 0, 0.752);
    font-family: Montserrat;
    font-size: 18.032px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px; /* 171.429% */
    letter-spacing: 0.258px;
}
#input-bar::placeholder{
    color: #25AD34;
font-family: Montserrat;
font-size: 18.032px;
font-style: normal;
font-weight: 500;
line-height: 52px; /* 171.429% */
letter-spacing: 0.258px;
}
#magnify{
    margin-left:2.5vw;
    padding-top:15px;
}

</style>