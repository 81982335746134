import { createRouter, createWebHistory } from "vue-router";
import AdminRoutes from "./admin.js";
import DashboardRoutes from "./dashboard.js";
import DriverRoutes from "./driver.js";
import OnboardingRoutes from "./onboarding.js";
import OutletRoutes from "./outlet.js";
import PaymentRoutes from "./payment.js";
import ReportRoutes from "./report.js";
import LandingPage from "../views/dashboard/landing-page.vue";
import MapPage from "../views/maps/map-page.vue";
let routes = [
  {
    name: "index",
    path: "/",
    component: LandingPage,
  },
  {
    name: "mapPage",
    path: "/map-page",
    component: MapPage,
  },
];
routes = routes.concat(
  AdminRoutes,
  DashboardRoutes,
  DriverRoutes,
  OnboardingRoutes,
  OutletRoutes,
  PaymentRoutes,
  ReportRoutes
);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
