<template>
    <div id="left-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
            <g clip-path="url(#clip0_754_1292)">
              <path d="M9.2207 2.8846L3.76114 8.34416L9.2207 13.8037L8.12879 15.9875L0.485408 8.34416L8.12879 0.700778L9.2207 2.8846Z" fill="#25AD34"/>
            </g>
            <defs>
              <clipPath id="clip0_754_1292">
                <rect width="8.73529" height="15.2868" fill="white" transform="matrix(-1 0 0 -1 9.2207 15.9875)"/>
              </clipPath>
            </defs>
          </svg>
    </div>
</template>

<style scoped>
#left-button{
    width:37px;
    height:37px;
    border-radius: 37px;
    cursor: pointer;
    border: 1.019px solid #25AD34;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>