const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
export default {
  async addAdmin(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/add-admin`;
      let reqBody = {
        name: payload.name,
        email: payload.email,
        password: payload.password,
      };
      const reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.data };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async changeMetrics(context, payload) {
    try {
      let reqBody = {
        subscription_cost: payload.subscription_cost,
        firstMile: payload.firstMile,
        subsequentMiles: payload.subsequentMiles,
        commission: payload.commission,
      };
      let reqUrl = `${hostName}/admin/edit-metrics`;
      let reqHeaders = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async getCurrMetrics() {
    try {
      let reqUrl = `${hostName}/admin/get-metrics`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
