<template>
    <div>
      <div id="container">
        <nav-bar></nav-bar>
        <div id="loader" v-if="Loading">
          <loader-comp></loader-comp>
        </div>
        <div id="content" v-if="Message==0">
        <driver-detail :value="Details" :orders="Orders.length" :vehicle="vehicle" :class="Suspended?'shadow':''"></driver-detail>
        <div id="divider" v-if="Suspended==false"></div>
        <suspended-comp :name="Details.name" :image="Details.driver_image" :suspended="Details.is_suspended" v-if="Suspended"></suspended-comp>
        <order-history :orders="Orders" :warnings="Details.warning" :id="Details.id" v-else></order-history>
        </div>
        <div id="error" v-else>
          <error-message v-if="Message==1" msg="No driver found"></error-message>
          <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
        </div>
    </div> 
   
   
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import DriverDetail from '../../components/driver/driver-details.vue'
import OrderHistory from '../../components/driver/driver-history.vue'
import SuspendedComp from '../../components/driver/suspended-comp.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
    components:{NavBar,DriverDetail,OrderHistory,SuspendedComp,ErrorMessage,ServerCrash,LoaderComp},
    data(){
    return{
      details:{},
      orders:[],
      vehicle:{},
      msg:0
    }
  },
  computed:{
    Suspended(){
      return this.$store.getters['driver/getSuspended']
    },
    Details(){
      return this.details
    },
    Message(){
      return this.msg
    },
    Orders(){
      return this.orders
    },
    Loading(){
                return this.$store.getters['user/getLoader']
            }
  },
    async mounted(){
        this.$store.commit('user/changeMenu',{value:2})
        this.$store.commit('user/setLoader',{value:true})
      let res = await this.$store.dispatch('driver/driverProfile',{id:this.$route.params.id})
      this.$store.commit('user/setLoader',{value:false})
      if(res.status==200){
        this.details = res.data 
        this.orders = res.orders
        this.vehicle = res.vehicle
        this.$store.commit('driver/setSuspended',{value:this.details.is_suspended})
      }
      else if(res.status==500){
        this.msg=2;
      }
      else{
        this.msg=1;
      }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.shadow{
    background: #D9D9D9;
  }
  #container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
  }
  #container::-webkit-scrollbar{
    display: none;
  }
  #content{
    width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display: grid;
    grid-template-columns: 35vw 1vw 49vw;
    }
    #content::-webkit-scrollbar{
    display: none;
    }
    #loader{
      width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display: flex;
    justify-content:center;
    align-items: center;;
    }
    #error{
      width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display:flex;
    align-items:center;
    justify-content: center;
    }
    #divider{
    width:3.5px;
    height:fill;
    background: #D9D9D9;
    margin-top:20vh;
    margin-bottom:5vh;
    }
</style>