<template>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div id="content" v-if="Message==0">
        <search-comp></search-comp>
       <div id="order-table">
        <div id="table-heading">
          <div class="heading">Order ID</div>
          <div class="heading">Status</div>
          <div class="heading">Payment</div>
          <div class="heading">Food Outlet</div>
          <div class="heading">Driver</div>
          <div class="heading">Driver Earning</div>
          <div class="heading">EE Share</div>
          <div class="heading">Payment Status</div>
          <div class="heading" style="margin-left:5vw;">Action</div>
      </div>
      <div class="table-divider"></div>
      <div id="table-content" v-for="order,index in Orders" :key="index">
        <div class="table-row">
          <div class="entry" style="width:7.5vw">{{ order.id}}</div>
          <div class="entry">{{ order.status }}</div>
          <div class="entry">£ {{ order.delivery_price }}</div>
          <div class="entry">{{ order.outlet_details.outlet_name }}</div>
          <div class="entry" v-if="order.driver.name">{{ order.driver.name }}</div>
          <div class="entry" v-else>...</div>
          <div class="entry">£ {{ order.driver_income }}</div>
          <div class="entry">£ {{ order.commission_amount }}</div>
          <div class="entry">{{ order.payment_status }}</div>
          <div class="table-btn" @click="redirect(order.id)">
           Payment Details
        </div>
        </div>
        <div class="table-divider"></div>
      </div>
       </div>
       <div id="pagination">
        <div class="page" v-if="Total>5" @click="prevRow">{{ prev }}</div>
        <div class="page" @click="prevFn" v-if="Total!=1">{{ prev1 }}</div>
        <div v-for="page,index in pages"  :key="index">
            <div :class="Selected == page?'active-page':'page'" @click="changePage(page)" v-if="page<=Total">
                {{ page }}
            </div>
        </div>
        
        <div class="page" @click="nextFn" v-if="Total!=1">{{ next1 }}</div>
        <div class="page" v-if="Total>5" @click="nextRow">{{ next }}</div>
    </div>
      </div>
      <div class="message-comp" v-if="Message==1">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
      <div class="message-comp" v-if="Message==2">
        <error-message msg="Failed to fetch orders"></error-message>
      </div>
      <div class="message-comp" v-if="Message==3">
        <empty-list msg="No order found"></empty-list>
      </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import SearchComp from '../../components/payment/search-comp.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import EmptyList from '../../components/general/messages/no-items.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
export default {
  components:{NavBar,SearchComp,LoaderComp,EmptyList,ErrorMessage,ServerCrash},
  data(){
    return{  
      orders:[],
      page:0,
      message:0,
      prev:'<<',
        prev1:"<",
        next:">>",
        next1:">",
        pages:[],
        activePage:1,
        totalPages:0
    }
  },
  computed:{
    Total(){
         return this.totalPages
    },
    Selected(){
        return this.activePage
    },
    Pages(){
        return this.pages
    },
    Orders(){
      return this.orders
    },
    Loading(){
      return this.$store.getters['user/getLoader']
    },
    Message(){
      return this.message;
    }
  },
  methods:{
    redirect(value){
      this.$router.push({name:'detailedPayment',params:{id:value}})
    },
    async fetchData(value){
      this.$store.commit('user/setLoader',{value:true})
      let res = await this.$store.dispatch('payment/payments',{value:value})
      this.$store.commit('user/setLoader',{value:false})
      if(res.status==200){
        this.orders = res.data
        this.totalPages = res.pages
        if(this.orders.length==0){
          this.message=3
        }
      }
      else if(res.status==500){
        this.message=1
      }
      else{
        this.message=2
      }
     
    },
    async prevFn(){
        this.activePage = this.activePage-1>0?this.activePage-1:this.activePage
        if(this.activePage<this.pages[0]){
            await this.prevRow()
        }
        else
        await this.fetchData(this.activePage)
    },
    async nextFn(){
        this.activePage = this.activePage+1<=this.totalPages?this.activePage+1:this.activePage
        if(this.activePage>this.pages[4]){
            await this.nextRow()
        }
        else
        await this.fetchData(this.activePage)
    },
    async prevRow(){
        if(this.pages[0]!=1){
            this.pages = this.pages.map(Element=>Element-5)
            await this.fetchData(this.pages[0])
      this.activePage = this.pages[0]
        }
    },
    async nextRow(){
      if(this.page[4]<=this.totalPages-5){
      this.pages = this.pages.map(Element=>Element+5)
      await this.fetchData(this.pages[0])
      this.activePage = this.pages[0]
      }
    },
    async changePage(value){
        this.activePage = value
        await this.fetchData(value)
    },
    
  },
 async mounted(){
   this.$store.commit('user/changeMenu',{value:3})
   this.page=1;
   await this.fetchData(1)
   this.pages = [1,2,3,4,5]
   this.activePage=1;
 }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
}
#loader,
.message-comp{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display:flex;
  align-items: center;
  justify-content: center;
}
#content::-webkit-scrollbar{
  display: none;
}
#order-buttons{
  float: right;
  margin-right:4vw;
  display: flex;
  margin-top:5vh;
}
#order-table{
  border-radius: 18px;
  border: 1.094px solid var(--Brand-color-3, #8D8D8D);
  background: #FFF;
  box-shadow: 0px 1.09416px 2.18831px 0px rgba(0, 0, 0, 0.10);
  width:78vw;
  margin-left:2vw;
  height:61vh;
  margin-top:4vh;
  overflow:auto;
  padding-top:1.5vh;
  padding-bottom:2vh;
  padding-left:1.75vw;
  padding-right:0.75vw;
}
#order-table::-webkit-scrollbar{
  display: none;
}
#table-heading{
  display: grid;
  grid-template-columns: 8vw 8vw 6vw 11vw 11vw 6vw 6vw 8vw 10vw;
  margin-bottom:2vh;
}
.heading{
  color: #413F3F;
font-family: Inter;
font-size: 15.318px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.219px;
}
.table-divider{
  background:#8d8d8d33;
  height:1px;
  width:75vw;
  margin-left:0.25vw;
  margin-top:1vh;
  margin-bottom:1vh;
}
.table-row{
  display:grid;
  grid-template-columns: 8vw 8vw 6vw 11vw 11vw 6vw 6vw 8vw 10vw;   
}
.entry{
  color: #413F3F;
font-family: Inter;
font-size: 15.318px;
font-style: normal;
font-weight: 500;
line-height: 41px;
letter-spacing: 0.219px;
word-wrap:break-word
}
.table-btn{
  border-radius: 10px;
background: #25AD34;
width:8vw;
height:41px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 15.506px;
font-style: normal;
font-weight: 400;
line-height: 41px;
cursor: pointer;
margin-left:4vw;
}
#pagination{
  width:85vw;
  margin-top:2vh;
  display: flex;
  justify-content: center;
}
.page{
  width:40px;
  height: 40px;
  border-radius: 40px;
  border:1px solid #25AD34;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-left:0.25vw;
  margin-right:0.25vw;
  font-family: Inter;
  font-size:20px;
  font-weight: 500;
  color:rgba(0, 0, 0, 0.684);
  cursor: pointer;
}
.active-page{
  width:40px;
  height: 40px;
  border-radius: 40px;
  background:  #25AD34;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-left:0.25vw;
  margin-right:0.25vw;
  font-family: Inter;
  font-size:20px;
  font-weight: 500;
  color:white;
  cursor: pointer;
}
</style>