<template>
    <div id="right-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
            <g clip-path="url(#clip0_754_1295)">
              <path d="M0.876952 13.8037L6.33651 8.34412L0.876953 2.88455L1.96887 0.700729L9.61225 8.34412L1.96886 15.9875L0.876952 13.8037Z" fill="#25AD34"/>
            </g>
            <defs>
              <clipPath id="clip0_754_1295">
                <rect width="8.73529" height="15.2868" fill="white" transform="translate(0.876953 0.700729)"/>
              </clipPath>
            </defs>
          </svg>
    </div>
</template>

<style scoped>
#right-button{
    width:37px;
    height:37px;
    border-radius: 37px;
    cursor: pointer;
    border: 1.019px solid #25AD34;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:1vw;
}
</style>