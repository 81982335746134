import PaymentsPage from "../views/payment/payments-page.vue";
import PaymentDetails from "../views/payment/payment-page.vue";
const routes = [
  {
    name: "payments",
    path: "/payments",
    component: PaymentsPage,
  },
  {
    name: "detailedPayment",
    path: "/detailed-payment/:id",
    component: PaymentDetails,
    props: true,
  },
];

export default routes;
