const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
import { db } from "../../firebase";
import { getDocs, query, collection, getDoc, doc } from "firebase/firestore";
export default {
  async homeData() {
    try {
      let reqUrl = `${hostName}/admin/home`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async getDrivers(context) {
    try {
      const q = query(collection(db, process.env.VUE_APP_DRIVER_COLLECTION));
      let docs = await getDocs(q);
      let driverCoordinates = [];
      let drivers = {};
      for (let i = 0; i < docs.docs.length; i++) {
        let data = docs.docs[i].data();
        driverCoordinates.push({
          coordinates: {
            lat: parseFloat(data.coordinatesX),
            lng: parseFloat(data.coordinatesY),
          },
        });
        drivers[docs.docs[i].id] = {
          lat: parseFloat(data.coordinatesX),
          lng: parseFloat(data.coordinatesY),
        };
      }
      context.commit(
        "driver/setCoordinates",
        { value: drivers },
        { root: true }
      );
      return driverCoordinates;
    } catch (err) {
      return [];
    }
  },
  async search(context, payload) {
    try {
      const searchValue = payload.value;
      const drivers = context.getters["getOrders"];
      const driverId = drivers[searchValue];
      if (driverId) {
        const q = doc(db, process.env.VUE_APP_DRIVER_COLLECTION, driverId);
        let response = await getDoc(q);
        const data = response.data();
        return { lat: data.coordinatesX, lng: data.coordinatesY };
      } else {
        return "1";
      }
    } catch (err) {
      return "2";
    }
  },
};
