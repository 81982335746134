<template>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
    </div>
      <div v-else>
        <div id="content" v-if="Message==0">
          <div id="col1">
            <search-comp></search-comp>
            <div id="refresh-button" @click="fetchData">Refresh</div>
            <recent-orders :value="Orders"></recent-orders>
          </div>
          <div id="divider"></div>
          <active-orders :value="Value"></active-orders>
          </div>
          <div id="error" v-else>
            <error-message v-if="Message==1" msg="Failed to fetch data"></error-message>
              <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
          </div>
      </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import SearchComp from '../../components/dashboard/search-comp.vue'
import RecentOrders from '../../components/dashboard/recent-orders.vue'
import ActiveOrders from '../../components/general/active-deliveries.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  components:{NavBar,SearchComp,RecentOrders,ActiveOrders,ErrorMessage,ServerCrash,LoaderComp},
  data(){
    return{
      val:{},
      msg:0,
      orders:{}
    }
  },
  computed:{
    Value(){
      return this.val
    },
    Message(){
    return this.msg
  },
  Orders(){
    return this.val.orders;
  },
  Loading(){
          return this.$store.getters['user/getLoader']
            }
  },
  methods:{
    async fetchData(){
      this.$store.commit('user/changeMenu',{value:0})
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('dashboard/homeData')
    this.$store.commit('user/setLoader',{value:false})
    if(res.status==200){
        this.val=res.data;
        this.$store.commit('dashboard/setActiveOutlet',{value:this.val.activeFoodOutetsCount})
        this.$store.commit('dashboard/setTotalOutlet',{value:this.val.totalFoodOutetsCount})
        this.$store.commit('dashboard/setActiveRiders',{value:this.val.activeDriversCount})
        this.$store.commit('dashboard/setTotalRiders',{value:this.val.totalDriversCount})
        this.$store.commit('dashboard/setOutletCoordinates',{value:this.val.outletCoordinatesList})
        let orders = res.data.orders
        let ordersMap ={} // creating a map of orderID and driver ID
        for(let i=0;i<orders.length;i++){
          ordersMap[orders[i].id] = orders[i].riderID || ''
        }
        this.$store.commit('dashboard/setOrders',{value:ordersMap})
        
    }
    else if(res.status==500){
      this.msg=2
    }
    else{
      this.msg=1
    }
    }
  },
  async mounted(){
    await this.fetchData()
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display: grid;
  grid-template-columns: 57vw 3vw 25vw;
}
#content::-webkit-scrollbar{
  display: none;
}
#loader{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#error{
  width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display:flex;
align-items:center;
justify-content: center;
}
#col1{
  width:55vw;
}
#divider{
  width:3.5px;
  height:fill;
  background: #D9D9D9;
  margin-top:10vh;
  margin-bottom:5vh;
}
#refresh-button{
  float:right;
  width:8vw;
  height:41px;
  border-radius:18px;
  background: #25AD34;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
  color:white;
  font-family: Inter;
  font-size:18px;
  font-weight:500
}

</style>