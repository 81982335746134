<template>
  <div>
    <div id="container">
    <nav-bar></nav-bar>
    <div id="loader" v-if="Loading">
      <loader-comp></loader-comp>
    </div>
    <div v-else>
      <div id="content"  v-if="Message==0">
        <outlet-details :value="Details" :orders="Orders" :class="Suspended?'shadow':''"></outlet-details>
        <div id="divider" v-if="Suspended==false"></div>
        <suspended-comp v-if="Suspended" :name="Details.restaurant_name" :image="Details.profile_image" :suspended="Details.is_suspended"></suspended-comp>
        <action-comp v-else :outlet="Details"></action-comp>
       </div>
       <div id="error" v-else>
         <error-message v-if="Message==1" msg="No Outlet found"></error-message>
         <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
       </div>
    </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import OutletDetails from '../../components/outlet/outlet-detail.vue'
import ActionComp from '../../components/outlet/action-comp.vue'
import SuspendedComp from '../../components/outlet/suspended-comp.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  components:{NavBar,OutletDetails,ActionComp,SuspendedComp,ErrorMessage,ServerCrash,LoaderComp},
  data(){
    return{
      details:{},
      orders:[],
      msg:0
    }
  },
  computed:{
    Suspended(){
      return this.$store.getters['outlet/getSuspend']
    },
    Details(){
      return this.details
    },
    Message(){
      return this.msg
    },
    Orders(){
      return this.orders;
    },
    Loading(){
                return this.$store.getters['user/getLoader']
            }
  },
  async mounted(){
    this.$store.commit('user/changeMenu',{value:1})
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('outlet/getOutlet',{value:this.$route.params.id})
    this.$store.commit('user/setLoader',{value:false})
    if(res.status==200){
      this.details = res.data;
      this.orders = res.orders
      this.$store.commit('outlet/setSuspend',{value:this.details.is_suspended})
    }
    else if(res.status==500){
        this.msg=2;
      }
      else{
        this.msg=1;
      }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.shadow{
  background: #D9D9D9;
}
#container{
  width:100vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y:auto;
  display: grid;
  grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
  display: none;
}
#content{
width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display: grid;
grid-template-columns: 50vw 3vw 32vw;
}
#content::-webkit-scrollbar{
display: none;
}
#loader{
  width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display: flex;
justify-content:center;
align-items: center;;
}
#divider{
width:3.5px;
height:fill;
background: #D9D9D9;
margin-top:10vh;
margin-bottom:5vh;
}
#error{
  width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display:flex;
align-items:center;
justify-content: center;
}
</style>