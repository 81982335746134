import { createStore } from 'vuex'
import admin from './admin/index.js'
import dashboard from './dashboard/index.js'
import driver from './driver/index.js'
import outlet from './outlet/index.js'
import payment from './payment/index.js'
import report from './report/index.js'
import user from './user/index.js'
export default createStore({
  modules: {
    admin:admin,
    dashboard:dashboard,
    driver:driver,
    outlet:outlet,
    payment:payment,
    report:report,
    user:user
  }
})
