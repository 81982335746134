<template>
    <div id="action-comp">
        <h2>Location</h2>
        <!--this is the map component-->
        <div id="map-box">
            <GMapMap :center="center"
            :zoom="15" :options="options" class="map">
            <GMapMarker
            v-for="(place, index) in markers"
            :key="index"
            :position="place.coordinates"
            :clickable="false"
          :draggable="false"
          :icon=icon></GMapMarker>
        </GMapMap>
        </div>
        <!-- <h2>Subscription</h2>
        <h3 style="margin-bottom:4vh;">User must follow the guidelines set by eat express and shouldn’t be reported by the drivers due to negligence of ill treating the delivery partners .</h3>
        <h2>User guidelines and reports</h2>
        <h3 style="margin-bottom:1.5vh;">User must follow the guidelines set by eat express and shouldn’t be reported by the drivers due to negligence of ill treating the delivery partners .</h3> -->
        <div id="action-buttons">
            <div id="action-col1">
                <div class="small-button" @click="suspend(0)">Suspend Outlet</div>
                <div class="small-button" @click="suspend(1)">Delete Outlet</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['outlet'], 
    data(){
        return{
            center:{},
            markers:[],
            icon:{
                url:require('../../assets/icons/customer-icon.png'),
            scaledSize: {width: 50, height: 50}
            },
            options:{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
        //   {
        //     featureType: 'all',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#d7d7d7' }], // Set the background color here (blue in this case)
        //   },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#FFFFFF',weight:3,gama:0.5,saturation: -50 }] // Set the road color here (blue in this case)
          },
          {
            featureType: 'label',
            elementType: 'labels.text.fill',
            stylers: [
              { visibility: 'off' }    // Hide text labels
            ]
          },
          
        ],
      },

        }
    },
     methods:{
        suspend(val){
            this.$store.commit('outlet/setSuspend',{value:true})
            this.$store.commit('outlet/setComp',{value:val})
        }
     },
     mounted(){
   setTimeout(()=>{
    this.center={
        lat:parseFloat(this.outlet.business_coordinatesX),
        lng:parseFloat(this.outlet.business_coordinatesY)
    }
    this.markers.push({coordinates:{
        lat:parseFloat(this.outlet.business_coordinatesX),
        lng:parseFloat(this.outlet.business_coordinatesY)
    }})
   },1000)
     }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#action-comp{
    width:32vw;
    height:fit-content;
    padding-top:7vh;
    overflow-x:hidden;
    overflow-y:auto;
    padding-bottom:4vh;
}
#action-comp::-webkit-scrollbar{
    display: none;
}
#action-comp h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
}
#map{
    margin-bottom:30vh;
}
#action-comp h3{
    color: #413F3F;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
margin-top:1px;
}
#action-comp h4{
    color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
}
#action-buttons{
    margin-top:2.5vh;
}
#action-col1{
    padding-left:1vw;
    padding-right:1vw;
    display: grid;
    grid-template-columns: repeat(2,13vw);
    gap:2vw;
}
.small-button{
    border-radius: 37px;
border: 1px solid #25AD34;
width:13vw;
height:50px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
color: #25AD34;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.2px;
}
.map{
    width: 29vw;
    height:38vh;
    margin-top:1vh;
    margin-left:0.5vw;
}
#map-box{
    width: 30vw;
    height:40vh;
    padding-top:0vh;
    border-radius: 18px;
    border: 2.5px solid var(--brand-color, #25AD34);
    margin-top:2vh;
    padding:0px;
}

</style>