<template>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="content">
        <div id="search-header">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" id="back" @click="back">
              <g clip-path="url(#clip0_847_20518)">
                <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
              </g>
              <defs>
                <clipPath id="clip0_847_20518">
                  <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                </clipPath>
              </defs>
            </svg>
          <h2>Add Admin</h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
              <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
            </svg>
      </div>
      <div id="form">
        <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Full Name" v-model="name" ref="input-1" @keydown="moveToNextInput($event, 'input-2')">
        <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
        <input type="text" :class="Error == 2?'input-error':'input-1'" placeholder="E-mail Address" v-model="email" ref="input-2" @keydown="moveToNextInput($event, 'input-3')">
        <h2 class="error-message" v-if="Error==2">{{ ErrorMessage }}</h2>
        <input type="password" :class="Error == 3?'input-error':'input-1'" placeholder="Password" v-model="password" ref="input-3" @keydown="moveToNextInput($event, 'input-4')">
        <h2 class="error-message" v-if="Error==3">{{ ErrorMessage }}</h2>
      </div>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div class="button" @click="submit">Add Admin</div>
      <div v-if="Message>0" id="msg-encase">
        <failed-comp v-if="Message==1" msg="Failed to add admin"></failed-comp>
        <failed-comp v-if="Message==12" msg="This email already exists"></failed-comp>
      <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
      <success-comp v-if="Message==3" msg="Admin added successfully"></success-comp>
      </div>
      </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import SuccessComp from '../../components/general/messages/success-message.vue'
import FailedComp from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  data(){
    return{
      name:'',
      email:'',
      password:'',
      errorField:'',
      errorMessage:"",
      msg:0
    }
  },
  components:{NavBar,FailedComp,ServerCrash,SuccessComp,LoaderComp},
  computed:{
            Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            },
            Message(){
              return this.msg
            },
            Loading(){
                return this.$store.getters['user/getLoader']
            }
        },
  methods:{
    back(){
      this.$router.go(-1)
    },
    async check2(value){ // email address
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                return emailPattern.test(value);
            },
    async submit(){
      let emailCheck = await this.check2(this.email);
      if(this.name==''){
        this.errorMessage="This field is required"
        this.errorField=1;
      }
      else if(this.email==''){
        this.errorMessage="This field is required"
        this.errorField=2;
      }
      else if(emailCheck==false){
        this.errorMessage="Please enter a valid email address"
        this.errorField=2;
      }
      else if(this.password==''){
        this.errorMessage="This field is required";
        this.errorField=3;
      }
      else if(this.password.length<8){
        this.errorMessage="Password should contain min 8 characters"
        this.errorField=3;
      }
      else{
        this.$store.commit('user/setLoader',{value:true})
        let res = await this.$store.dispatch('admin/addAdmin',{name:this.name,email:this.email,password:this.password})
        this.$store.commit('user/setLoader',{value:false})
        if(res.status==200){
          this.name=''
          this.password=''
          this.email=''
          this.note=''
          this.msg=3;
          setTimeout(()=>{
            this.msg=0;
          },1500)
        }
        else if(res.status==500){
          this.msg=2;
          setTimeout(()=>{
            this.msg=0;
          },1500)
        }
        else if(res.status==422){
          this.msg=12;
          setTimeout(()=>{
            this.msg=0;
          },1500)
        }
        else{
          this.msg=1;
          setTimeout(()=>{
            this.msg=0;
          },1500)
        }
      }
    },
    async moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        if(nextInputName == 'input-4'){
          await this.submit()
        }
        else{
          this.$refs[nextInputName].focus();
        }
      }
              }
  },
  async mounted(){
    this.$store.commit('user/changeMenu',{value:5})
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
}
#content::-webkit-scrollbar{
  display: none;
}
#search-header{
  display: flex;
  padding-left:1vw;
  align-items: center;
}
#back{
  margin-right:2vw;
  cursor: pointer;
}
#search-header h2{
  color: #333;
font-family: Inter;
font-size: 24.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
  margin-left:4px;
}
#form{
  padding-top:5vh;
  padding-left:2vw;
  display: grid;
  grid-template-rows: repeat(4,auto);
}
.input-1,
    .input-1:focus,
    .input-1::selection{
        width:20vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:18px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:20vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:18px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
  .button{
    margin-left:4.5vw;
    width:15vw;
    height:45px;
    border-radius: 48px;
background: var(--brand-color, #25AD34);
margin-top:2.5vh;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .error-message{
    font-family: Inter;
    font-size:14px;
    color:rgba(255, 0, 0, 0.601);
    font-weight: 400;
    margin-left:1vw;
    margin-top:-16px;
}
#msg-encase{
  width:fill;
  height: fill;
  display: flex;
  justify-content: center;
  margin-top:-20vh;
}
#loader{
  width:auto;
  display: flex;
  justify-content: center;
}
</style>