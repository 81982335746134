import AddAdmin from "../views/admin/add-admin.vue";
import PricingPage from "../views/admin/change-metrics.vue";
const routes = [
  {
    name: "addAdmin",
    path: "/add-admin",
    component: AddAdmin,
  },
  {
    name: "pricing",
    path: "/pricing",
    component: PricingPage,
  },
];

export default routes;
