const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
export default {
  async paymentDetails(context, payload) {
    try {
      const reqUrl = `${hostName}/admin/order-detail/${payload.value}`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.orderDTO };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async payments(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/payments/${payload.value}`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.ordersList,
          pages: res.data.totalPages,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
