// import ReportsPage from "../views/report/reports-page.vue";
// import ReportDetails from "../views/report/report-page.vue";
const routes = [
  // {
  //   name: "reports",
  //   path: "/reports",
  //   component: ReportsPage,
  // },
  // {
  //   name: "reportDetails",
  //   path: "/report-detail/:id",
  //   component: ReportDetails,
  //   props: true,
  // },
];

export default routes;
