import DriversPage from "../views/driver/drivers-page.vue";
import DriverPage from "../views/driver/driver-page.vue";
import DriverHistory from "../views/driver/driver-history.vue";
import PaymentsPage from "../views/driver/payment-details.vue";
import ManageDrivers from "../views/driver/manage-drivers.vue";
import DriverRequest from "../views/driver/driver-requests.vue";
import WeaklyEarnings from "../views/driver/weekly-earnings.vue";
const routes = [
  {
    name: "drivers",
    path: "/driver",
    component: DriversPage,
  },
  {
    name: "driver",
    path: "/driver/:id",
    component: DriverPage,
    props: true,
  },
  {
    name: "driverHistory",
    path: "/driver-history/:id",
    component: DriverHistory,
    props: true,
  },
  {
    name: "driverPayment",
    path: "/driver-payment/:id",
    component: PaymentsPage,
    props: true,
  },
  {
    name: "manageDrivers",
    path: "/manage-drivers",
    component: ManageDrivers,
  },
  {
    name: "driverRequest",
    path: "/driver-request/:id",
    component: DriverRequest,
    props: true,
  },
  {
    name: "driverWeakly",
    path: "/driver-weekly/:id",
    component: WeaklyEarnings,
    props: true,
  },
];

export default routes;
